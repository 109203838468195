/* ============================= *\
      Donation Steps - STARTS
\* ============================= */

export const SELECT_PROJECT = "selectProject";

export const DONATE = "donate";

export const CONTACT = "contact";

export const PAYMENT = "payment";

export const THANK_YOU = "thankyou";

/* === Donation Steps - ENDS === */
